@font-face {
  font-family: "Gordita";
  font-display: swap;
  src: url(./assets/fonts/Gordita/Gordita.woff2) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Gordita";
  font-display: swap;
  src: url(./assets/fonts/Gordita/GorditaMedium.woff2) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Gordita";
  font-display: swap;
  src: url(./assets/fonts/Gordita/GorditaBold.woff2) format("woff");
  font-weight: 700;
}

@import url("https://use.typekit.net/mkn7qyb.css");

* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

html {
  height: 100%;
  width: 100vw;
  overflow: hidden;
}

#root {
  white-space: pre-line;
}

body {
  height: 100%;
  width: 100vw;
  overflow: hidden;
  font-family: "Gordita", "pragmatica-extended", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  scrollbar-width: none;
}

#root {
  height: 100%;
  width: 100vw;
  overflow: hidden;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #000000;
}

a .page-option {
  color: inherit;
}

img {
  user-select: none;
}

a:active {
  color: inherit;
}

.page-choice:hover .page-title {
  color: #2f50ff !important;
}

li {
  margin-left: 25px;
  font-family: "Gordita";
  font-size: 18px;
}

.carousel.carousel-slider,
.carousel,
.slider-wrapper {
  overflow: visible !important;
}

.control-dots {
  width: 100vw !important;
  margin: 0 !important;
}

svg {
  transform: unset !important;
}

/* React-Player styling */


.react-player {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

/* Swiper styles */

.swiper {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}

.swiper-3d .swiper-slide-shadow {
  background: transparent !important;
}

.roladex-image {
  background-color: #ffffff;
}

.avatar-swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-slide-visible:not(.swiper-slide-active) {
  opacity: .55
}

.avatar-swiper-slide:not(.swiper-slide-visible) img {
  display: none;
}

.blog-swiper {
  user-select: none;
}

.blog-swiper img {
  user-select: none;
}

.blog-swiper .swiper-pagination {
  right: 10px;
  width: fit-content;
}

.blog-bullets {
  height: 12px !important;
  width: 12px !important;
  border-radius: 50% !important;
  background-color: #feffe6 !important;
  margin-right: 15px !important;
}

.hero-images .swiper-slide {
  display: flex;
  align-items: center;
}

/* removes chat box on the hubstpo iframe  */
#scheduler-iframe #hubspot-messages-iframe-container {
  display: none;
}

/* Roladex style */


.swiper#word-roladex {
  flex: unset !important;
  background-color: #EEF5FE;
  border-radius: 16px;
  margin: 0 !important;
  transition: width 1s ease-in-out;
}

/* HTML styling received from Hubspot api call  */

#post-body {
  padding: 0;
}

#post-body h1 em img {
  max-width: 100vw !important;
  margin-bottom: 30px;
  @media screen and (max-width: 1000px) {
    max-width: 100% !important;
  }
}

#post-body h3 {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

#post-body h4 {
  width: 100%;
  margin-bottom: 10px;
}

#post-body p {
  margin-bottom: 25px;
}

#post-body ul {
  width: 100% !important;
}

#post-body li {
  font-size: 16px;
}